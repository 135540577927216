<template>
    <div class="gongxiu">
        <logo class="logo"></logo>
        <div class="box buddha-bg animate__animated animate__fadeIn">
            <div class="header">
                <a-icon class="back" @click="$router.go(-1)" type="left-circle" />
                <div class="title">
                    <img src="@/assets/imgs/icons/03.png" class="title-img" alt="">
                    <span class="text">{{ $t('home.navTitles.gongxiu') }}</span>
                </div>
            </div>
            <div class="container-pd">
                <a-row :gutter="16" class="nav-bars">
                    <a-col :span="8" v-for="(item, index) in donateItems" :key="index">
                        <div class="nav-bar rxys" @click="donate(item)">
                            <div class="icon">
                                <img src="@/assets/imgs/icons/01.png" alt="">
                            </div>
                            <div class="title">
                                {{item.name}}
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div v-if="showDonate" class="donate-wrapper buddha-bg">
                <donate :donateType="donateType" @handle-close="closeDonate"></donate>
            </div>
        </div>
    </div>
</template>
<script>
import Logo from '@/components/Logo.vue';
import Donate from "@/components/Donate.vue";
export default {
    name: "Gongxiu",
    data() {
        return {
            showDonate: false,
            donateItems: [],
            donateType: {}
        };
    },
    components: { Logo, Donate },
    mounted() {
        this.donateItems = [
            // {id: 1, name: this.$t('gongxiu.rxys'), code: 'rxys' },
            // {id: 2, name: this.$t('gongxiu.js'), code: 'js' },
            // {id: 3, name: this.$t('gongxiu.gf'), code: 'gf' },
            // {id: 4, name: this.$t('gongxiu.gz'), code: 'gz' },
            // {id: 5, name: this.$t('gongxiu.fh'), code: 'fh' },
            // {id: 6, name: this.$t('gongxiu.zyin'), code: 'zyin' },
            // {id: 7, name: this.$t('gongxiu.gdeng'), code: 'gdeng' },
            // {id: 8, name: this.$t('gongxiu.gdx'), code: 'gdx' },
            // {id: 9, name: this.$t('gongxiu.svx'), code: 'svx' },
        ];
        this.getData();
    },
    methods: {
        donate(item){
            this.showDonate = true;
            this.donateType = item;
        },
        closeDonate(){
            this.showDonate = false;
        },
        getData(){
            // this.loading = true;
            this.$http.call(this.$http.api.gxtypes).then(res=>{
                console.log(res);
                // this.loading = false;
                let resp = res.data;
                if(resp.code=='200'){
                    this.donateItems = resp.data;
                }
                
            },res=>{
                console.log('something goes wrong...');
                console.log(res.response);
                if(res.response.status == '401'){
                    this.$store.commit('setToken','');
                    this.$router.push({name:'login'});
                }
            });
        },
    },
};
</script>
<style lang="less" scoped>
.gongxiu {
    width:100%;
    height: 100%;
    padding: 0 50px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    .logo {
        padding: 20PX 0;
    }
    .box{
        position: relative;
        overflow: hidden;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 30PX;
        .header {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15PX;
            .back {
                position: absolute;
                left: 15PX;
                font-size: 20PX;
                color: #a8a8a8;
            }
            .title {
                font-weight: bold;
                font-size: 16PX;
                color: @primary-color;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .title-img {
                    height: 50PX;
                }
            }
        }
        .container-pd {
            overflow-y: scroll;
            overflow-x: hidden;
            .nav-bars{
                display: flex;
                flex-wrap: wrap;
                margin: 0 -12PX;
                .nav-bar{
                    background: #ffffff;
                    color: @primary-color;
                    padding: 20PX;
                    margin: 20PX auto;
                    border-radius: 15PX;
                    box-shadow: 1PX 2PX 4PX @primary-color-shadow;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .icon {
                        font-size: 24PX;
                        padding-bottom: 7PX;
                        opacity: .8;
                        img {
                            width: 28PX;
                        }
                    }
                }
            }
            
        }
        .donate-wrapper {
            background: #fafafa;
            width: 100%;
            position: absolute;
            top: 0; left: 0; bottom: 0;
            overflow-y: scroll;
            overflow-x: hidden;
        }
        
    }
    
}
</style>
