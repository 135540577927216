<template>
    <div class="donate">
      <div class="header">
        <a-icon class="back" @click="close" type="left" />
        <div class="title">
            <img src="@/assets/imgs/icons/01.png" class="title-img" alt="">
            <span class="text">{{ donateType.name }}</span>
        </div>
        <a-icon class="info-icon" v-if="step==1" @click="step=0" type="info-circle" />
      </div>
      <div v-if="step==0" class=" animate__animated animate__fadeIn">
        <div class="content" v-html="donateType.desc"></div>
        <div class="mg30 btn btn-primary" @click="step=1">捐献</div>
      </div>
      <a-form-model v-if="step==1" :model="form" class=" animate__animated animate__fadeIn">
        <a-form-model-item label="功德金额">
          <a-input class="amount" type="number" size="large" v-model="form.amount">
            <!-- <a-radio-group slot="suffix" v-model="form.btype">
              <a-radio value="0">
                BCT
              </a-radio>
              <a-radio value="1">
                USDT
              </a-radio>
            </a-radio-group> -->
            <div slot="suffix" class="btn-btype-group">
                <span class="btn-btype" :class="form.btype==0?'on':''" @click="form.btype=0">
                    BCT
                </span>
                <span class="btn-btype" :class="form.btype==1?'on':''" @click="form.btype=1">
                    USDT
                </span>
            </div>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="功德主">
          <a-input size="large" placeholder="匿名" v-model="form.name" />
        </a-form-model-item>
        <a-form-model-item label="联系方式">
          <a-input size="large" placeholder="匿名" v-model="form.tel" />
        </a-form-model-item>
        <a-form-model-item label="祈福">
          <!-- <div class="tags">
            <a-tag color="#b37e25" v-for="(tag, index) in blessTags" :key="index">
              {{ tag }}
            </a-tag>
          </div> -->
          <word-cloud class="word-cloud" :words="blessTags" @handleClick="clickWord"></word-cloud>
          <a-input rows="3" v-model="form.desc" type="textarea" />
        </a-form-model-item>
        <a-form-model-item >
          <div class="btn btn-primary" @click="onSubmit">捐献</div>
        </a-form-model-item>
      </a-form-model>
    </div>
</template>

<script>
import Shelldao from "@/web3/tron/shelldao";
import WordCloud  from '@/components/WordCloud';
export default {
  name: 'Donate',
  props: {
    donateType: {
        type: Object
    }
  },
  components: { WordCloud },
  data () {
    return {
      loading: false,
      step: 0,
      userUsdtBalance: 0,
      userBctBalance: 0,
      approvedUsdtAmount: 0,
      approvedBctAmount: 0,
      blessTags: ["风调雨顺", "五谷丰登", "国泰民安", "社会和谐", "学业进步", "才华横溢", "金榜题名", "前程似锦", "身体健康", "长命百岁", "平安顺遂", "事事如意", "心想事成", "万事胜意", "事业有成", "财源广进", "家庭和睦", "幸福美满", "爱情甜蜜", "婚姻幸福", "吉祥如意", "福寿安康", "智慧增长", "明理达道", "精力充沛", "活力四射", "梦想成真", "理想实现", "心情愉快", "快乐无边", "岁月静好", "现世安稳", "灾难远离", "厄运消除", "机遇连连", "好运不断", "心愿达成", "梦想成真", "财富丰盈", "富足一生", "智慧开启", "洞察秋毫", "地位显赫", "受人敬仰", "容颜美丽", "青春常驻", "出行平安", "旅途愉快", "职场顺利", "步步高升", "子孙满堂", "福泽绵长", "姻缘美满", "百年好合", "债务清偿", "财务自由", "疾病康复", "健康长寿", "心情舒畅", "无忧无虑", "友情长存", "友谊深厚", "学有所成", "出类拔萃", "事业顺利", "蒸蒸日上", "家庭和谐", "幸福安康", "人生如意", "十全十美"],
      form: {
        amount: '',
        name: '',
        tel: '',
        btype: '0',
        desc: '',
      },
    }
  },
  mounted () {
    Shelldao.init((r)=>{
      r.gUsdtToken.balanceOf(r.gWalletAddress).call().then((res)=>{
        this.userUsdtBalance = res.toNumber() / 1000000;
        console.log('usdt balance:', this.userUsdtBalance);
      });
      r.gBctToken.balanceOf(r.gWalletAddress).call().then((res)=>{
        this.userBctBalance = res.toNumber() / 1000000;
        console.log('bct balance:', this.userBctBalance);
      });
      Shelldao.getDonateApprovedAmount('USDT',(res)=>{
        let _approvedAmount=0;
        if(res.remaining===undefined){
            _approvedAmount = res.toNumber();
        }else{
            _approvedAmount = res.remaining.toNumber();
        }
        this.approvedUsdtAmount = _approvedAmount/1000000;
        console.log('approved usdt:', this.approvedUsdtAmount);
      });
      Shelldao.getDonateApprovedAmount('BCT',(res)=>{
        let _approvedAmount=0;
        if(res.remaining===undefined){
            _approvedAmount = res.toNumber();
        }else{
            _approvedAmount = res.remaining.toNumber();
        }
        this.approvedBctAmount = _approvedAmount/1000000;
        console.log('approved bct:', this.approvedBctAmount);
      })
    });
  },
  destroyed () {
  },
  methods: {
    clickWord(e){
      this.form.desc += (' ' + e);
    },
    async onSubmit() {
      if(this.loading) return;
      if(!this.form.amount){
        this.$message.error('请填写捐贈金額'); return;
      }
      if(!this.form.name){
        this.form.name = '匿名';
      }
      if(!this.form.tel){
        this.form.tel = '匿名';
      }
      if(!this.form.desc) {
        this.$message.error('请填写祈福');
        return;
      }
      let data = this.form;
      data.gtype = this.donateType.code;
      this.loading = true;
      this.$http.call(this.$http.api.gongxiu,{
          data
      }).then(res=>{
          // console.log(res);
          this.loading = false;
          let resp = res.data;
          if(resp.code=='200'){
            console.log(resp.data)
            this.handleDonate(resp.data)
          }else{
            console.log(resp.data)
            this.$message.error(resp.data);
          } 
      },res=>{
        this.loading = false;
          console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    close() {
      this.$emit('handle-close');
    },
    handleDonate(data){
      let btypes = ['BCT','USDT'];
      console.log('donate '+btypes[data.btype])
      let _this = this;
      if(data.btype == 0){
        if(this.approvedBctAmount <= data.amount*1000000){
          this.loading = true;
          Shelldao.donateApproveToken(data.amount*10000000, 'BCT',(res)=>{
            console.log(res)
            Shelldao.donate(0, data.amount*1000000, data.id, ()=>{
              _this.$message.success('success');
              _this.loading = false;
            });
          });
        }else{
          Shelldao.donate(0, data.amount*1000000, data.id, ()=>{
            _this.$message.success('success');
            _this.loading = false;
          });
        }
      }else{
        if(this.approvedUsdtAmount <= data.amount*1000000){
          this.loading = true;
          Shelldao.donateApproveToken(data.amount*10000000, 'USDT',(res)=>{
            console.log(res)
            Shelldao.donate(1, data.amount*1000000, data.id, ()=>{
              _this.$message.success('success');
              _this.loading = false;
            });
          });
        }else{
          Shelldao.donate(1, data.amount*1000000, data.id, ()=>{
            _this.$message.success('success');
            _this.loading = false;
          });
        }
      }
    }
  },
  computed: {
  },
  
}
</script>

<style lang="less" scoped>
.donate {
  padding: 30px 50px;
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 15PX;
    .back {
        position: absolute;
        left: 0;
        font-size: 20PX;
        color: #a8a8a8;
    }
    .title {
        font-weight: bold;
        font-size: 16PX;
        color: @primary-color;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title-img {
            height: 50PX;
        }
    }
    .info-icon {
      position: absolute;
      right: 0;
      font-size: 20PX;
      color: #a8a8a8;
    }
  }
  .content {
    text-align: left;
  }
  .amount {
    .btn-btype-group {
      padding: 5PX 0;
      border-radius: 15PX;
      overflow: hidden;
      font-weight: bold;
      .btn-btype {
        background: #efca6b;
        padding: 3PX 15PX;
        color: #b37e25;
      }.on {
        background: #b37e25;
        color: #fff;
      }
    }
  }
  .word-cloud {
    margin-top: -30PX;
  }
  .btns {
    margin-left: -15PX;
    margin-right: -15PX;
    .btn {
      background: #d8d8d8aa;
      padding: 10PX;
      border-radius: 7PX;
      flex: 1; margin: 15PX;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .token-icon {
        height: 38PX;
        width: 38PX;
      }
    }
  }
}
</style>
