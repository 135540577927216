<template>
<div class="word-cloud">
    <div ref="tagCloud" @click.prevent="handleClick"></div>
</div>
</template>

<script>
export default {
data() {
    return {
        // words: ["风调雨顺", "五谷丰登", "国泰民安", "社会和谐", "学业进步", "才华横溢", "金榜题名", "前程似锦", "身体健康", "长命百岁", "平安顺遂", "事事如意", "心想事成", "万事胜意", "事业有成", "财源广进", "家庭和睦", "幸福美满", "爱情甜蜜", "婚姻幸福", "吉祥如意", "福寿安康", "智慧增长", "明理达道", "精力充沛", "活力四射", "梦想成真", "理想实现", "心情愉快", "快乐无边", "岁月静好", "现世安稳", "灾难远离", "厄运消除", "机遇连连", "好运不断", "心愿达成", "梦想成真", "财富丰盈", "富足一生", "智慧开启", "洞察秋毫", "地位显赫", "受人敬仰", "容颜美丽", "青春常驻", "出行平安", "旅途愉快", "职场顺利", "步步高升", "子孙满堂", "福泽绵长", "姻缘美满", "百年好合", "债务清偿", "财务自由", "疾病康复", "健康长寿", "心情舒畅", "无忧无虑", "友情长存", "友谊深厚", "学有所成", "出类拔萃", "事业顺利", "蒸蒸日上", "家庭和谐", "幸福安康", "人生如意", "十全十美"],
    };
},
props: {
    words: {
        type: Array
    }
},
mounted() {
    const TagCloud = require('TagCloud');

    // const container = '.tagcloud';
    // const texts = [
    //     '3D', 'TagCloud', 'JavaScript',
    //     'CSS3', 'Animation', 'Interactive',
    //     'Mouse', 'Rolling', 'Sphere',
    //     '6KB', 'v2.x',
    // ];
    const options = {
        reverseDirection: true,
        containerClass: 'mg0auto',
        itemClass: 'color-primary',
    };
    const shuffled = this.words.sort(() => 0.5 - Math.random());
    const shuffledArr = shuffled.slice(0, 16);
    TagCloud(this.$refs.tagCloud, shuffledArr, options);
},
methods: {
    handleClick(e){
        if (e.target.className === 'color-primary') {
            this.$emit('handleClick', e.target.innerText)
        }
    }
}
};
</script>
  
<style lang="less" scoped>
.word-cloud {
    
}
</style>
  